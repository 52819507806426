import React from "react";
import Para from "./sub-comp/Para";
import info from "../Data/info";
import { HiArrowNarrowRight } from "react-icons/hi";
import { Link } from "react-scroll";
import { Cursor, useTypewriter } from "react-simple-typewriter";
import { useSelector } from "react-redux";

const Home = () => {
  const darkTheme = useSelector((state) => state.darkTheme);

  const [words] = useTypewriter({
    words: [
      "Java",
      "Typescript",
      "Javascript",
      "Springboot",
      "React",
      "NextJS",
      "Redux",
      "Kafka",
      "Docker",
      "MongoDB",
      "MS SQL Server",
    ],
    loop: true,
    typeSpeed: 180,
    deleteSpeed: 80,
  });
  return (
    <div name="home" className="w-full h-screen">
      <div className="max-w-[1000px] mx-auto px-8 flex justify-center h-full">
        <div className="flex flex-col justify-center gap-2 h-full">
          <p className="text-pink-600 font-semibold text-center text-[21px] md:text-2xl py-1">
            Hi my name is
          </p>

          <h1
            className={`${
              darkTheme ? "text-primary" : " text-secondary"
            } text-center text-4xl md:text-6xl font-bold py-1`}
          >
            Abhinath Jadhav
          </h1>
          <p
            className={`${
              darkTheme ? "text-gray-400 " : " text-gray-500 "
            } text-center text-4xl md:text-6xl font-bold`}
          >
            I'm Fullstack Developer
          </p>
          <div
            className={`${
              darkTheme ? "text-primary " : " text-secondary "
            }py-4 text-center`}
          >
            <Para para={info.homeInfo} />
          </div>

          <div className="flex justify-center">
            <span className=" text-pink-600 font-bold text-5xl pl-2">
              {words}
            </span>
            <span className="text-pink-600 font-bold text-5xl">
              <Cursor cursorStyle="|" />
            </span>
          </div>

          <div className="flex justify-center mt-5">
            <div
              className={`${
                darkTheme
                  ? "text-primary "
                  : " text-secondary border-secondary hover:text-primary hover:border-gray-400 "
              } flex justify-center w-36 cursor-pointer border-2 group py-2 px-2  hover:bg-pink-600`}
            >
              <Link
                to="projects"
                smooth={true}
                duration={500}
                className="flex items-center justify-center gap-3"
              >
                {" "}
                View work
                <span className=" group-hover:rotate-90 duration-300">
                  <HiArrowNarrowRight size={20} />
                </span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Home;
