import React from "react";

const SkillCard = (props) => {
  let style = props.style?.css;

  return (
    <div
      className={` ${style ? style : ""}  
         w-24 md:w-36 flex flex-col justify-center items-center p-2 md:p-4 m-2 md:m-4 shadow-md shadow-gray-700 border rounded-lg border-gray-500 hover:scale-110 duration-300 `}
    >
      <img className="w-10 md:w-20 md:p-2 mx-auto" src={props.img} alt="Html" />
      <p className="text-[12px] mt-2 md:mt-4 md:text-lg">{props.title}</p>
    </div>
  );
};

export default SkillCard;
