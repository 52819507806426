import React from "react";
import Title from "./sub-comp/Title";

const Contact = () => {
  return (
    <div name="contact" className="h-screen w-full mx-auto">
      <div className="flex flex-col h-full px-8 items-center justify-center">
        <div>
          <Title title="Contact Me" />
        </div>
        <div className="mt-10 md:flex gap-5 w-full justify-center">
          <div className="text-center text-lg md:text-right w-full">
            <p className="text-2xl font-bold">Contacts</p>

            <p className="mt-2">
              Mobile Number : <span>9503698655</span>
            </p>

            <div>
              <p>
                <span>
                  <a
                    href="https://www.linkedin.com/in/abhinath18/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LinkedIn/Abhinath-Jadhav
                  </a>
                </span>
              </p>
            </div>

            <div>
              <p>
                <a
                  href="mailto:abhinath.jadhav18@gmail.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  abhinath.jadhav18@gmail.com
                </a>
              </p>
            </div>
            <p>
              <a
                href="https://github.com/abhinath-jadhav"
                target="_blank"
                rel="noreferrer"
              >
                github.com/abhinath-jadhav
              </a>
            </p>
          </div>
          <div className="mt-5 md:mt-0 text-center md:text-left md:border-l-2 w-full">
            <div className="md:pl-4">
              <p className="text-2xl font-bold">Address</p>
              <p className="mt-2 text-lg">
                <span>Jadhav Niwas </span> <br />
                Near Jijau Anath ashram, <br />
                Kole bus stop, Shinganwadi <br />
                Karad 415103
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
