import React, { useState } from "react";
import { FaBars, FaTimes, FaGithub, FaLinkedin } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { Link } from "react-scroll";
import { useDispatch, useSelector } from "react-redux";
import CircularButton from "./sub-comp/CircularButton";
import { showDropDown } from "./redux/reducer/dropDownSlice";
import { changeTheme } from "./redux/reducer/themeSlice";
import Togglebutton from "./sub-comp/Togglebutton";

function Navbar() {
  const navData = [
    { id: "home", text: "Home" },
    { id: "about", text: "About" },
    { id: "skills", text: "Skills" },
    { id: "projects", text: "Projects" },
    { id: "experiance", text: "Experiance" },
    { id: "contact", text: "Contact" },
  ];

  const sections = [
    "home",
    "about",
    "skills",
    "projects",
    "experiance",
    "contact",
  ];

  const [currentSectionIndex, setCurrentSectionIndex] = useState(1);

  const scrollNext = () => {
    const nextIndex = (currentSectionIndex + 1) % sections.length;
    setCurrentSectionIndex(nextIndex);
  };

  const dispatch = useDispatch();
  const darkTheme = useSelector((state) => state.darkTheme);

  const nav = useSelector((state) => state.dropDown);

  const handleNavClick = () => {
    dispatch(showDropDown());
  };

  return (
    <div
      className={`${
        darkTheme ? "bg-dark text-light" : " bg-light text-secondary"
      } fixed top-0 left-0 right-0 flex items-center justify-end max-w-[1640px] mx-auto px-4 h-[80px] z-10`}
    >
      {/* Logo */}
      <div className="absolute left-4 top-3 z-10 cursor-pointer">
        <Link to="home" smooth={true} duration={500}>
          <p className="text-5xl font-[500] mt-[0.4rem] font-poppins">
            <span className="text-pink-600 font-semibold">{"{"}</span>AJ
            <span className="text-pink-600 font-semibold">{"}"}</span>
          </p>
        </Link>
      </div>

      {/* Menu */}
      <ul className="hidden md:flex gap-4 items-center px-4 text-xl">
        {navData.map((item) => (
          <li key={item.id}>
            <Link to={item.id} smooth={true} duration={500}>
              {item.text}
            </Link>
          </li>
        ))}
      </ul>

      {/* theme toggle */}
      <div className="flex justify-center item-center gap-3">
        <div
          className={`${
            darkTheme ? "" : ""
          } cursor-pointer z-10 rounded-lg md:text-lg`}
          onClick={() => dispatch(changeTheme())}
        >
          {darkTheme ? (
            <Togglebutton title="Dark" on />
          ) : (
            <Togglebutton title="Light" />
          )}
        </div>

        {/* Hamber sign */}
        <div
          onClick={() => handleNavClick()}
          className="z-10 md:hidden cursor-pointer flex items-center"
        >
          {!nav ? <FaBars size={35} /> : <FaTimes size={35} />}
        </div>
      </div>

      {/* Mobile menu */}
      <ul
        className={`
          ${!nav ? "hidden" : ""} ${
          darkTheme ? "bg-dark" : "bg-light"
        } w-full h-screen flex flex-col gap-10 justify-center items-center px-4 absolute top-0 left-0 text-3xl`}
      >
        {navData.map((item) => (
          <li key={item.id}>
            <Link
              to={item.id}
              smooth={true}
              duration={500}
              onClick={handleNavClick}
            >
              {item.text}
            </Link>
          </li>
        ))}
      </ul>

      {/* Scroll button */}
      <div className="fixed md:hidden flex-col bottom-2 w-screen">
        <div className="flex ml-10 md:ml-0 justify-center">
          <Link
            to={sections[currentSectionIndex]}
            smooth={true}
            duration={500}
            onClick={scrollNext}
          >
            <CircularButton />
          </Link>
        </div>
      </div>

      {/* Social icons */}
      <div className="fixed hidden lg:flex flex-col bottom-2 lg:top-[35%] lg:left-0">
        <ul>
          <li className="flex w-[180px] h-[60px] justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-blue-600">
            <a
              href="https://www.linkedin.com/in/abhinath18/"
              className="flex justify-between items-center w-full text-[21px] text-gray-300"
              target="_blank"
              rel="noreferrer"
            >
              LinkedIn <FaLinkedin size={30} />
            </a>
          </li>
          <li className="w-[180px] h-[60px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#333333]">
            <a
              href="https://github.com/abhinath-jadhav"
              className="flex justify-between items-center w-full text-[21px] text-gray-300"
              target="_blank"
              rel="noreferrer"
            >
              Github <FaGithub size={30} />
            </a>
          </li>
          <li className="w-[180px] h-[60px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#6fc2b0]">
            <a
              href="mailto:abhinath.jadhav18@gmail.com"
              className="flex justify-between items-center w-full text-[21px] text-gray-300"
              target="_blank"
              rel="noreferrer"
            >
              Mail <HiOutlineMail size={30} />
            </a>
          </li>
          <li className="w-[180px] h-[60px] flex justify-between items-center ml-[-120px] hover:ml-[-10px] duration-300 bg-[#565f69]">
            <a
              href="https://www.canva.com/design/DAF2Rn7Lof4/SVn-cTe15xAR1MUmS2JZnA/view?utm_content=DAF2Rn7Lof4&utm_campaign=designshare&utm_medium=link&utm_source=editor"
              className="flex justify-between items-center w-full md:text-[21px] text-gray-300"
              target="_blank"
              rel="noreferrer"
            >
              Resume <BsFillPersonLinesFill size={30} />
            </a>
          </li>
        </ul>
      </div>

      {/* Mobile social  */}
      <div className={" hidden md:block lg:hidden fixed left-0 bottom-1"}>
        <ul className="flex flex-col">
          <li className="w-[120px] h-[40px] flex justify-between items-center  duration-300 bg-blue-600 ml-[-75px] hover:ml-0">
            <a
              href="mailto:abhinath.jadhav18@gmail.com"
              className="flex justify-between items-center w-full text-[14px]  text-gray-300"
              target="_blank"
              rel="noreferrer"
            >
              Mail <FaLinkedin size={20} />
            </a>
          </li>
          <li className="w-[120px] h-[40px] flex justify-between items-center  duration-300 bg-[#565f69] ml-[-75px] hover:ml-0">
            <a
              href="https://drive.google.com/file/d/1fEi59kSEzg4pmxV7to9WFOYh8IpfX78g/view?usp=drive_link"
              className="flex justify-between items-center w-full text-[14px]  text-gray-300 "
            >
              Resume <BsFillPersonLinesFill size={20} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default Navbar;
