import About from "./components/About";
import Contact from "./components/Contact";
import Experiance from "./components/Experiance";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Skills from "./components/Skills";
import Helmet from "react-helmet";
import favicon from "./assets/favicon.ico";
import favicon2 from "./assets/favicon2.ico";
import Project from "./components/Project";
import { useSelector } from "react-redux";

function App() {
  const darkTheme = useSelector((state) => state.darkTheme);
  return (
    <main
      className={`${
        darkTheme ? "bg-dark text-light" : " bg-light text-secondary font-[500]"
      }`}
    >
      <Helmet>
        <title>Abhinath-Jadhav</title>
        {darkTheme ? (
          <link rel="icon" type="image/ico" href={favicon} />
        ) : (
          <link rel="icon" type="image/ico" href={favicon2} />
        )}
      </Helmet>
      <Navbar />
      <Home />
      <About />
      <Skills />
      <Project />
      <Experiance />
      <Contact />
    </main>
  );
}

export default App;
