import React from "react";

const Togglebutton = (props) => {
  return (
    <div
      className={`${
        props.on
          ? "flex bg-light text-dark "
          : " bg-dark text-light flex flex-row-reverse"
      } h-[40px] w-[80px] items-center justify-between px-2 rounded-3xl`}
    >
      <p className="text-center text-[13px] font-bold">{props.title}</p>
      <div
        className={`${
          props.on ? "bg-dark " : "bg-light "
        }rounded-[50%] flex justify-between items-center w-[28px] h-[28px]  border`}
      ></div>
    </div>
  );
};

export default Togglebutton;
