const info = {
  aboutInfo:
    "My journey spans over 5+ years in industry, including 3.5 years in Software Development, I excel in Java Springboot, React, JavaScript, TypeScript, and Next.js. Proficient in Kafka, Docker, Sql Server and MongoDB for seamless processes.",

  homeInfo:
    "I’m a full-stack developer specializing in building (and occasionally designing) exceptional digital experiences. Currently, I’m focused on building responsive full-stack web applications.",
  educational:
    "I hold a Bachelor's degree in Aeronautical Engineering from the autonomous institution ADCET, Ashta, located in Sangli district. Building upon this foundation, I previously completed a Diploma in Mechanical Engineering from Gaurishankar Polytechnic, Satara.",
};

export default info;
