import React from "react";
import Title from "./sub-comp/Title";

const Experiance = () => {
  const months = [
    "JAN",
    "FEB",
    "MAR",
    "APR",
    "MAY",
    "JUNE",
    "JULY",
    "AUG",
    "SEPT",
    "OCT",
    "NOV",
    "DEC",
  ];

  return (
    <div name="experiance" className="h-screen w-full mx-auto">
      <div className="flex flex-col h-full px-8 md:items-center justify-center">
        <div className="text-6xl flex justify-center text-center">
          <Title title="Experiance" />
        </div>
        <div className="mt-10 text-lg md:flex gap-5">
          <div className="text-center md:text-right ">
            <p className="text-3xl font-bold">Sonovision Aetos, Bangalore</p>
            <p className="mt-2">
              Trainee Engineer | AUG{" "}
              <span className="text-pink-500 font-bold">2018</span> - JAN{" "}
              <span className="text-pink-500 font-bold">2021 </span>
            </p>
            <p>
              Associate Engineer | FEB{" "}
              <span className="text-pink-500 font-bold">2021</span> - APR{" "}
              <span className="text-pink-500 font-bold">2022 </span>
            </p>
          </div>
          <div className="mt-5 md:mt-0 text-center md:text-left md:border-l-2">
            <p className="text-3xl font-bold md:pl-5">
              R Systems International, Noida
            </p>
            <p className="mt-2 md:pl-5">
              Software Engineer | MAY{" "}
              <span className="text-pink-500 font-bold">2022</span> -{" "}
              {months[new Date().getMonth()]}{" "}
              <span className="text-pink-500 font-bold">
                {new Date().getFullYear()}
              </span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experiance;
