import React from "react";

const ProjectCard = ({ data }) => {
  const { title, github, img, demo } = data;
  return (
    <div className="group relative">
      <img className="w-[350px] border rounded-lg" src={img} alt={title} />

      <div className="hidden group-hover:block absolute top-0 w-full h-full bg-black/60">
        <div className="flex justify-center items-end pb-4 gap-6 h-full">
          <a
            className="bg-slate-50 text-black font-semibold px-2 py-1 rounded-md hover:border border-slate-50 hover:bg-black hover:text-slate-50"
            href={github}
            rel="noreferrer"
            target="_blank"
          >
            Github
          </a>
          <a
            className="bg-pink-600 text-slate-50 font-semibold px-2 py-1 rounded-md hover:border border-pink-600 hover:bg-slate-50 hover:text-pink-600"
            href={demo}
            rel="noreferrer"
            target="_blank"
          >
            Demo
          </a>
        </div>
      </div>
    </div>
  );
};

export default ProjectCard;
