import { configureStore } from "@reduxjs/toolkit";
import dropDownReducer from "./reducer/dropDownSlice";
import themeReducer from "./reducer/themeSlice";

const store = configureStore({
  reducer: {
    dropDown: dropDownReducer,
    darkTheme: themeReducer,
  },
});

export default store;
