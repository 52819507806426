import React from "react";

const Title = (props) => {
  return (
    <span className="md:w-full text-5xl font-bold border-b-4 border-pink-600  py-2">
      {props.title}
    </span>
  );
};

export default Title;
