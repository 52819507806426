import React from "react";
import Title from "./sub-comp/Title";
import HTML from "../assets/html.png";
import CSS from "../assets/css.png";
import SkillCard from "./sub-comp/SkillCard";
import MsSql from "../assets/mssql.svg";
import Next from "../assets/next.webp";
import Mongo from "../assets/mongo.png";
import Javascript from "../assets/javascript.png";
import ReactImg from "../assets/react.png";
import Spring from "../assets/Springboot.png";
import Java from "../assets/java-icon.svg";
import Typescript from "../assets/typescript.png";
import { useSelector } from "react-redux";

const Skills = () => {
  const style = { css: "hidden sm:flex" };
  const styl1 = { css: "hidden lg:flex" };

  const darkTheme = useSelector((state) => state.darkTheme);
  return (
    <div
      name="skills"
      className={`${
        darkTheme
          ? "bg-dark text-light font-[500] "
          : " bg-light text-dark font-[600] "
      } w-full h-screen px-4`}
    >
      <div className="max-w-[1000px] mx-auto flex flex-col w-full p-4 h-full px-8 md:items-center justify-center">
        <div className="text-center">
          <Title title={"Skills"} />
        </div>

        <div className="py-4 grid grid-cols-2 mt-5 md:grid-cols-3 lg:grid-cols-5 w-full justify-items-center">
          <SkillCard img={Java} title="JAVA" />
          <SkillCard img={Javascript} title="JAVASCRIPT" />
          <SkillCard img={Typescript} title="TYPESCRIPT" />
          <SkillCard img={Spring} title="SPRINGBOOT" />
          <SkillCard img={ReactImg} title="REACT" />
          <SkillCard img={Next} title="NEXT JS" />
          <SkillCard img={HTML} style={style} title="HTML" />
          <SkillCard img={CSS} style={styl1} title="CSS" />
          <SkillCard img={Mongo} title="MONGODB" />
          <SkillCard img={MsSql} title="MS SQL" />
        </div>
      </div>
    </div>
  );
};

export default Skills;
