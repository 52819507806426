import React from "react";
import Title from "./sub-comp/Title";
import Para from "./sub-comp/Para";
import info from "../Data/info";
import { useSelector } from "react-redux";

const About = () => {
  const darkTheme = useSelector((state) => state.darkTheme);
  return (
    <div name="about" className="h-screen w-full mx-auto">
      <div
        className={`${
          darkTheme ? "bg-dark text-primary " : " bg-light text-secondary "
        } flex flex-col h-full px-8 md:items-center justify-center`}
      >
        <div className="text-center">
          <Title title="About" />
        </div>
        <div className="max-w-[1000px] mt-10 md:grid grid-cols-2 gap-3">
          <p className="text-center md:text-right md:mx-2 mb-4">
            <p className="text-2xl font-semibold"> Career Information</p>
            <Para para={info.aboutInfo} />
          </p>
          <div className="text-center md:text-left md:mr-2  md:border-l-2">
            <div className="md:pl-5">
              <p className="text-2xl font-semibold">Eductional Information</p>
              <Para para={info.educational} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
