import React from "react";
import { HiArrowNarrowRight } from "react-icons/hi";

const CircularButton = (props) => {
  return (
    <div className="w-14 md:w-20 h-14 md:h-20 flex justify-center items-center bg-pink-600 text-gray-300 border-slate-50 rounded-[50%] cursor-pointer">
      <span className="rotate-90">
        <HiArrowNarrowRight size={40} />
      </span>
    </div>
  );
};

export default CircularButton;
