import React from "react";

import Title from "./sub-comp/Title";
import ProjectCard from "./sub-comp/ProjectCard";

import Manflag from "../assets/manflag.jpg";
import Flipkart from "../assets/flipkart.jpg";

const Project = () => {
  return (
    <div name="projects" className="h-screen w-full mx-auto">
      <div className="flex flex-col h-full px-8 md:items-center justify-center">
        <div className="mb-10 flex justify-center">
          <Title title="Projects" />
        </div>

        <div className="mt-20 justify-center items-center grid md:grid-cols-2 gap-4 md:gap-12">
          <ProjectCard
            data={{
              title: "Manflag Fitness",
              img: Manflag,
              github: "https://github.com/abhinath-jadhav/manflag-next",
              demo: "https://manflag.netlify.app/",
            }}
          />
          <ProjectCard
            data={{
              title: "Flipkart",
              img: Flipkart,
              github: "https://github.com/abhinath-jadhav/flipkart-clone",
              demo: "https://flipkart-app-clone.netlify.app/",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Project;
