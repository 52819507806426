import React from "react";

const Para = (props) => {
  return (
    <div>
      <p className="max-w-[700px] text-[18px] ">{props.para}</p>
    </div>
  );
};

export default Para;
