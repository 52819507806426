import { createSlice } from "@reduxjs/toolkit";
const showDropDownSlice = createSlice({
  name: "navbar",
  initialState: false,
  reducers: {
    showDropDown: (state) => !state,
  },
});

export const { showDropDown } = showDropDownSlice.actions;

export default showDropDownSlice.reducer;
